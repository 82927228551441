import { burntAreaHazardReductionLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import BurntAreaHazardReductionLayer from "./BurntAreaHazardReductionLayer";
import { QUERY_OPTIONS_COMMON } from "./constants";

const BurntAreaHazardReduction = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  if (!isLayerActive(burntAreaHazardReductionLayer.id)) {
    return null;
  }

  const state = getLayerState(burntAreaHazardReductionLayer.id);

  return (
    <BurntAreaHazardReductionLayer
      layerId={burntAreaHazardReductionLayer.id}
      opacity={state?.opacity}
      queryOptions={QUERY_OPTIONS_COMMON}
    />
  );
};

export default BurntAreaHazardReduction;
