import type { Feature } from "geojson";
import formatDate from "../../../utils/formatDate/formatDate";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import {
  type MapboxMouseEvent,
  isMultiPolygonFeature,
  isPolygonFeature,
} from "../types";
import type { BurntAreaHazardReductionFeature } from "./gis";

export interface BurntAreaHazardReductionData {
  endDate: string;
  hrName: string;
  method: string;
  objectId: number;
}

export function getPropertiesFromFeature(
  feature: Feature,
  event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
): BurntAreaHazardReductionInteractionProperties | null {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const { lngLat } = event;
  const burntAreaFeature = feature as BurntAreaHazardReductionFeature;

  return {
    data: {
      hrName: burntAreaFeature.properties?.HRNAME ?? "",
      endDate: burntAreaFeature.properties
        ? formatDate(burntAreaFeature.properties?.ENDDATE, "fullTimeAndDate")
        : "",
      method: burntAreaFeature.properties?.METHOD ?? "",
      objectId: burntAreaFeature.properties?.OBJECTID,
    } as BurntAreaHazardReductionData,
    featureId: burntAreaFeature.properties?.OBJECTID,
    lngLat,
  };
}

export interface BurntAreaHazardReductionInteractionProperties
  extends FeatureInteractionProperties {
  data: BurntAreaHazardReductionData;
}

export type BurntAreaHazardReductionInteractionState =
  FeatureInteractionState<BurntAreaHazardReductionInteractionProperties>;
