import BurntAreaHazardReductionPopup from "../../popup/BurntAreaHazardReductionPopup/BurntAreaHazardReductionPopup";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import type { QueryOptions } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { getPropertiesFromFeature } from "./interactions";
import useBurntAreaHazardReductionMapLayer from "./useBurntAreaHazardReductionMapLayer";

interface BurntAreaHazardReductionLayerProps {
  layerId: string;
  opacity?: number;
  queryOptions: QueryOptions;
}

const BurntAreaHazardReductionLayer = ({
  layerId,
  opacity,
  queryOptions,
}: BurntAreaHazardReductionLayerProps) => {
  useBurntAreaHazardReductionMapLayer({
    layerId,
    opacity,
    queryOptions,
  });

  const { clickedState, deactivateClickState, hoveredState } =
    useLayerInteractions({
      getPropertiesFromFeature,
      layerId,
    });

  useInteractionFeatureState({
    clickedState,
    getFeatureId: (p) => p.featureId,
    hoveredState,
    sourceId: layerId,
  });

  return (
    <BurntAreaHazardReductionPopup
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};

export default BurntAreaHazardReductionLayer;
