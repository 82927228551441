import { FieldGrid } from "@app/design-system";
import styled from "styled-components";
import { EMDASH } from "../../../lib/strings";
import type { BurntAreaHazardReductionInteractionProperties } from "../../map/BurntAreaHazardReduction/interactions";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export interface BurntAreaHazardReductionPopupDetailProps {
  properties: BurntAreaHazardReductionInteractionProperties;
}

const BurntAreaHazardReductionPopupDetail = ({
  properties,
}: BurntAreaHazardReductionPopupDetailProps) => {
  return (
    <StyledBody>
      <FieldGrid>
        <FieldGrid.Item label="HR Name:">
          {properties.data.hrName || EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item label="Date:">
          {properties.data.endDate || EMDASH}
        </FieldGrid.Item>
      </FieldGrid>
    </StyledBody>
  );
};

export default BurntAreaHazardReductionPopupDetail;
