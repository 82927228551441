import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import type { QueryOptions } from "../hooks/useMapServerQueryData/useMapServerQueryData";

export const BURNT_AREA_HAZARD_REDUCTION_QUERY_URL =
  `${getMapServerProxyBasepath()}/arcgis/rest/services/Operations/BRIMSFeatures/MapServer/3/query` as const;

export const BURNT_AREA_HAZARD_REDUCTION_FIELD_NAMES = [
  "ENDDATE",
  "HRNAME",
  "METHOD",
  "OBJECTID",
] as const;

export const QUERY_OPTIONS_COMMON = {
  minZoom: 8,
  queryParams: {
    outFields: BURNT_AREA_HAZARD_REDUCTION_FIELD_NAMES.join(","),
  },
} as const satisfies QueryOptions;
