import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import {
  HABITABLE_PROPERTIES_BASE,
  HABITABLE_PROPERTIES_SWIMMING_POOLS,
} from "./constants";

const HABITABLE_PROPERTIES_ID = "habitableProperties";
const HABITABLE_PROPERTIES_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/2/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "roof_height, swimming_pool_adjacent, is_residential",
    where: "is_residential='Yes'",
  },
};

interface HabitablePropertiesLayer {
  isSwimmingPoolsActive: boolean;
  opacity?: number;
}

const HabitablePropertiesLayer = ({
  isSwimmingPoolsActive,
  opacity = 1,
}: HabitablePropertiesLayer) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    HABITABLE_PROPERTIES_URL,
    QUERY_OPTIONS,
  );

  useEffect(() => {
    map.addSource(HABITABLE_PROPERTIES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: HABITABLE_PROPERTIES_ID,
        type: "fill-extrusion",
        source: HABITABLE_PROPERTIES_ID,
        paint: {
          "fill-extrusion-height": ["get", "roof_height"],
          "fill-extrusion-color": HABITABLE_PROPERTIES_BASE,
        },
        minzoom: 15,
      },
      MapLevel.FS_BACKGROUND,
    );

    return () => {
      map.removeLayer(HABITABLE_PROPERTIES_ID);
      map.removeSource(HABITABLE_PROPERTIES_ID);
    };
  }, [map]);

  useEffect(() => {
    if (isSwimmingPoolsActive) {
      map.setPaintProperty(HABITABLE_PROPERTIES_ID, "fill-extrusion-color", [
        "match",
        ["get", "swimming_pool_adjacent"],
        "Yes",
        HABITABLE_PROPERTIES_SWIMMING_POOLS,
        HABITABLE_PROPERTIES_BASE,
      ]);
    } else {
      map.setPaintProperty(
        HABITABLE_PROPERTIES_ID,
        "fill-extrusion-color",
        HABITABLE_PROPERTIES_BASE,
      );
    }
  }, [isSwimmingPoolsActive, map]);

  useEffect(() => {
    const source = map?.getSource(HABITABLE_PROPERTIES_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(
      HABITABLE_PROPERTIES_ID,
      "fill-extrusion-opacity",
      opacity,
    );
  }, [map, opacity]);

  return null;
};

export default HabitablePropertiesLayer;
