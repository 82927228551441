import { Text } from "@app/design-system";
import type { Feature } from "geojson";
import noop from "lodash/noop";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { MapLevel } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import PreviewSpatialPopup from "../../popup/PreviewSpatialPopup/PreviewSpatialPopup";
import useMapContext from "../Map/useMapContext";
import {
  InteractionStateType,
  type FeatureInteractionProperties,
} from "../MapInteractions/types";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { useMapServerQueryData } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource, type MapboxMouseEvent } from "../types";
import {
  fireMapperGISLayers,
  getFireMapperGISUrls,
  type FireMapperLayerProps,
} from "./utils";

interface DownloadableFeatureProperties extends FeatureInteractionProperties {
  featureId: string;
  ms_url?: string;
}

const getPropertiesFromFeature = (
  feature: Feature,
  event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
): DownloadableFeatureProperties => ({
  // NOTE: The available properties are limited by the "outFields" in the fetch, but match the FireMapperCommonMetadata type from the NSWRFS FireMapperAdapter
  featureId: feature.properties!.objectid,
  lngLat: event.lngLat,
  ms_url: feature.properties!.ms_url,
});

const StyledDownloadLinkPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// NOTE: As these layers only render at a certain distance, we lock all views to this to keep it consistent
const zoomSettings = { minzoom: 11, maxzoom: 0 };

const FireMapperMediaRasterLayer = (props: FireMapperLayerProps) => {
  const layerId = `fireMapper${props.layer}${props.layerType}`;
  const interactiveLayerId = `${layerId}Interactive`;
  const map = useMapContext();
  const accessToken = useAuthAccessToken();
  const { mapUrl, tileUrl } = getFireMapperGISUrls(
    props.layer,
    props.layerType,
  );

  // NOTE: Make sure this is a constant reference, or the reference changing will cause useMapServerQueryData to constantly re-request data
  const queryOptions = useMemo(
    () => ({
      queryParams: {
        outFields: [props.dataKey, "objectid", "ms_url"].join(","),
      },
    }),
    [props.dataKey],
  );
  const { data } = useMapServerQueryData(mapUrl, queryOptions);

  const {
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: interactiveLayerId,
  });

  useInteractionFeatureState({
    getFeatureId: (properties) => properties.featureId,
    clickedState,
    hoveredState,
    sourceId: interactiveLayerId,
  });

  useEffect(() => {
    if (!accessToken) return noop;

    const controller = new AbortController();
    controller.signal.addEventListener("abort", () => {
      if (map.getLayer(interactiveLayerId)) {
        map.removeLayer(interactiveLayerId);
      }

      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }

      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }

      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    });

    if (!map.getSource(layerId)) {
      const query = buildMapboxBBoxQuery({
        dpi: "96",
        format: "png32",
        layers: `show:${fireMapperGISLayers[props.layerType][props.layer]}`,
        transparent: "true",
        bboxSR: "3857",
        imageSR: "3857",
        size: "512,512",
        f: "image",
      });

      map.addSource(layerId, {
        type: "raster",
        tileSize: 512,
        tiles: [`${tileUrl}?${query}`],
      });
    }

    if (!map.getLayer(layerId)) {
      map.addLayer(
        { ...zoomSettings, id: layerId, type: "raster", source: layerId },
        MapLevel.FS_SYMBOLS_PRIORITY,
      );
    }

    if (!map.getSource(interactiveLayerId)) {
      map.addSource(interactiveLayerId, {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    if (!map.getLayer(interactiveLayerId)) {
      map.addLayer(
        {
          ...zoomSettings,
          id: interactiveLayerId,
          type: "circle",
          filter: ["!=", ["to-string", ["get", "ms_url"]], ""],
          source: interactiveLayerId,
          paint: {
            "circle-radius": 16,
            "circle-color": "#AAAAAA",
            "circle-opacity": [
              "case",
              [
                "any",
                [
                  "boolean",
                  ["feature-state", InteractionStateType.HOVERED],
                  false,
                ],
                [
                  "boolean",
                  ["feature-state", InteractionStateType.CLICKED],
                  false,
                ],
              ],
              0.8,
              0.4,
            ],
          },
        },
        MapLevel.SYMBOLS,
      );
    }

    return () => controller.abort();
  }, [
    accessToken,
    interactiveLayerId,
    layerId,
    map,
    props.dataKey,
    props.layer,
    props.layerType,
    tileUrl,
  ]);

  useEffect(() => {
    const source = map.getSource(interactiveLayerId);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, interactiveLayerId, map]);

  return (
    <>
      {hoveredState.properties && (
        <PreviewSpatialPopup
          onClose={deactivateHoverState}
          state={hoveredState}
          type="hover"
        >
          <StyledDownloadLinkPopup>
            <Text size="subtitleMd">Asset is downloadable</Text>
          </StyledDownloadLinkPopup>
        </PreviewSpatialPopup>
      )}

      {clickedState.properties && (
        <PreviewSpatialPopup
          onClose={deactivateClickState}
          state={clickedState}
          type="click"
        >
          <StyledDownloadLinkPopup>
            <a
              href={clickedState.properties.ms_url}
              rel="noreferrer"
              target="_blank"
            >
              Download asset
            </a>
          </StyledDownloadLinkPopup>
        </PreviewSpatialPopup>
      )}
    </>
  );
};

export default FireMapperMediaRasterLayer;
