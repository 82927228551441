import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import {
  useMapServerQueryData,
  type QueryOptions,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { isMaplibre } from "../utils/isMaplibre";
import { BURNT_AREA_HAZARD_REDUCTION_QUERY_URL } from "./constants";

interface UseBurntAreaHazardReductionMapLayerParams {
  layerId: string;
  opacity?: number;
  queryOptions: QueryOptions;
}

const useBurntAreaHazardReductionMapLayer = ({
  layerId,
  opacity = 1,
  queryOptions,
}: UseBurntAreaHazardReductionMapLayerParams) => {
  const map = useMapContext();
  const theme = useTheme();
  const { data } = useMapServerQueryData(
    BURNT_AREA_HAZARD_REDUCTION_QUERY_URL,
    queryOptions,
  );

  useEffect(() => {
    const activeLineId = `${layerId}-active`;
    const textLayerId = `${layerId}-text`;
    map.addSource(layerId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        type: "line",
        id: layerId,
        source: layerId,
        paint: {
          "line-color": [
            "case",
            [
              "any",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "boolean",
                ["feature-state", InteractionStateType.CLICKED],
                false,
              ],
            ],
            theme.colors.decorative.manualBorderMedium,
            "#ffaa01",
          ],
          "line-width": 4,
        },
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        type: "line",
        id: activeLineId,
        source: layerId,
        paint: {
          "line-color": theme.colors.decorative.manualBorderMedium,
          "line-width": 4,
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
            1,
            0,
          ],
        },
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        id: textLayerId,
        type: "symbol",
        source: layerId,
        layout: {
          "text-field": ["get", "METHOD"],
          "text-font": isMaplibre(map) ? ["Noto Sans Bold"] : ["DIN Pro Bold"],
          "text-size": 12,
          "symbol-placement": "point",
        },
        paint: {
          "text-color": theme.colors.neutrals.text,
          "text-halo-color": "#FFF",
          "text-halo-width": 1,
          "text-halo-blur": 0,
        },
        minzoom: 12,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getLayer(activeLineId)) {
        map.removeLayer(activeLineId);
      }
      if (map.getLayer(textLayerId)) {
        map.removeLayer(textLayerId);
      }
      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    };
  }, [
    layerId,
    map,
    theme.colors.decorative.manualBorderMedium,
    theme.colors.neutrals.text,
  ]);

  useEffect(() => {
    map.setPaintProperty(layerId, "line-opacity", opacity);
  }, [layerId, map, opacity]);

  useEffect(() => {
    const source = map.getSource(layerId);

    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, layerId, map]);
};

export default useBurntAreaHazardReductionMapLayer;
