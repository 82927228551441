import { Info, InfoFilled, ToggleIconButton } from "@app/design-system";
import React from "react";
import { predictionLegend } from "../../legends/PredictionLegend/PredictionLegend";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";

const PredictionLegendToggleButton = () => {
  const {
    setActiveLegend,
    state: { activeLegend },
  } = useActiveLayersContext();
  return (
    <ToggleIconButton
      iconOn={InfoFilled}
      iconOff={Info}
      label="Legend"
      onClick={() => {
        if (activeLegend?.id === predictionLegend.id) {
          setActiveLegend({ legend: null });
          return;
        }
        setActiveLegend({ legend: predictionLegend });
      }}
      selected={activeLegend?.id === predictionLegend.id}
      variant="secondary"
      size="sm"
    />
  );
};

export default PredictionLegendToggleButton;
