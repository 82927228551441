import type { Incident } from "../../../../.rest-hooks/types";
import type { IncidentAlertLevel } from "../../../../.rest-hooks/types/incidents.yml";
import { getAlertLevelIconName } from "../../../config/alertLevel";
import type { IncidentPointFeature } from "../IncidentPoint/utils";

const incidentAlertLevelPriority: Record<IncidentAlertLevel, number> = {
  "Emergency Warning": 4,
  "Watch and Act": 3,
  Advice: 2,
  "Planned Burn": 1,
  Other: 0,
};

const getIncidentAlertLevelPriority = (alertLevel: IncidentAlertLevel) => {
  return incidentAlertLevelPriority[alertLevel];
};

const getIncidentPoint = (incident: Incident): IncidentPointFeature => ({
  type: "Feature",
  geometry: incident.attributes.location,
  properties: {
    alertLevelPriority: getIncidentAlertLevelPriority(
      incident.attributes.alertLevel,
    ),
    iconId: getAlertLevelIconName(incident.attributes.alertLevel),
    incidentId: incident.id,
    name: incident.attributes.name,
    type: "incident",
  },
});

export default getIncidentPoint;
