import { useEffect } from "react";
import useUpdatingRef from "../../../hooks/useUpdatingRef";
import useMapContext from "../Map/useMapContext";
import setInteractionFeatureState from "../utils/setInteractionFeatureState";
import {
  type FeatureInteractionProperties,
  type FeatureInteractionState,
  InteractionStateType,
} from "./types";

interface UseInteractionFeatureStateParams<
  P extends FeatureInteractionProperties,
> {
  getFeatureId: (properties: P) => string | number;
  clickedState: FeatureInteractionState<P> | null;
  hoveredState: FeatureInteractionState<P> | null;
  sourceId: string;
}

/**
 * Used for synchronising the Mapbox/Maplibre "feature state" of a feature in
 * response to changes in the clicked and hovered states provided by
 * `useLayerInteractions`. You only need to synchronise this feature state if
 * you are using it inside the layer styles to set a hover or click style on
 * your layer: if the symbol or polygon doesn't need to change style when the
 * user hovers or clicks on it, then you don't need this hook.
 *
 * @see https://docs.mapbox.com/help/tutorials/create-interactive-hover-effects-with-mapbox-gl-js/#feature-state-and-expressions
 */
const useInteractionFeatureState = <P extends FeatureInteractionProperties>({
  getFeatureId,
  clickedState,
  hoveredState,
  sourceId,
}: UseInteractionFeatureStateParams<P>) => {
  const map = useMapContext();

  const getFeatureIdRef = useUpdatingRef(getFeatureId);

  useEffect(() => {
    if (!clickedState?.isActive) return;

    const clickedFeatureId = getFeatureIdRef.current(clickedState.properties);

    setInteractionFeatureState({
      featureId: clickedFeatureId,
      isActive: true,
      map,
      sourceId,
      type: InteractionStateType.CLICKED,
    });

    return () => {
      setInteractionFeatureState({
        featureId: clickedFeatureId,
        isActive: false,
        map,
        sourceId,
        type: InteractionStateType.CLICKED,
      });
    };
  }, [clickedState, getFeatureIdRef, map, sourceId]);

  useEffect(() => {
    if (!hoveredState?.isActive) return;

    const hoveredFeatureId = getFeatureIdRef.current(hoveredState.properties);

    setInteractionFeatureState({
      featureId: hoveredFeatureId,
      isActive: true,
      map,
      sourceId,
      type: InteractionStateType.HOVERED,
    });

    return () => {
      setInteractionFeatureState({
        featureId: hoveredFeatureId,
        isActive: false,
        map,
        sourceId,
        type: InteractionStateType.HOVERED,
      });
    };
  }, [hoveredState, getFeatureIdRef, map, sourceId]);
};

export default useInteractionFeatureState;
