import { AVLPortableNotKnown, StaticIconWrapper } from "@app/design-system";
import styled from "styled-components";
import {
  AVL_PORTABLE_ICONS,
  AVL_STATUS,
} from "../../legends/AVLLegend/AVLLegend";

const StyledHeader = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

const getApplianceStatusIcon = (status: string | undefined) => {
  if (!status) return AVL_STATUS.NOT_KNOWN;

  const formattedStatus = status.trim().replace(" ", "_").toUpperCase();

  if (Object.keys(AVL_STATUS).includes(formattedStatus)) {
    return AVL_STATUS[formattedStatus];
  }

  return AVL_STATUS.NOT_KNOWN;
};

const getPortableStatusIcon = (status: string | undefined) => {
  if (!status) return AVLPortableNotKnown;

  return (
    AVL_PORTABLE_ICONS.find(
      ({ description }) => description.toUpperCase() === status.toUpperCase(),
    )?.icon ?? AVLPortableNotKnown
  );
};

export interface AVLPopupHeaderProps {
  status: string | undefined;
  type: "appliance" | "portable";
}

const AVLPopupHeader = ({ status, type }: AVLPopupHeaderProps) => {
  return (
    <StyledHeader>
      <StaticIconWrapper
        icon={
          type === "appliance"
            ? getApplianceStatusIcon(status)
            : getPortableStatusIcon(status)
        }
        size="xs"
      />
      {status}
    </StyledHeader>
  );
};

export default AVLPopupHeader;
