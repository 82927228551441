import { useUpdatingRef } from "@kablamo/kerosene-ui";
import styled from "styled-components";
import type { Legend } from "../../../config/layers/layers";
import DrawerContent from "../../drawers/DrawerOverlay/DrawerContent";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import MapRailDrawerCell from "./MapRailDrawerCell";
import MapRailDrawerListItem, { getGridColumn } from "./MapRailDrawerListItem";
import { useMapRailContext } from "./MapRailProvider";

const StyledMapRailDrawerList = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 20.5rem);
  grid-auto-flow: column;
  flex: 1;
  gap: 0.5rem;
  min-height: 0;
`;

const StyledLegend = styled.div`
  padding: 0.75rem;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-radius: ${(p) => p.theme.borderRadiuses.md}px;
`;

interface MapRailLegendDrawerProps {
  gridColumn: number;
  isSelected: boolean;
  legend: Legend | null;
  onClose: () => void;
}

const MapRailLegendDrawer = ({
  gridColumn,
  isSelected,
  legend,
  onClose,
}: MapRailLegendDrawerProps) => {
  const legendRef = useUpdatingRef(legend);
  const { component: Component, id, label } = legend ?? legendRef.current ?? {};

  return (
    <MapRailDrawerListItem gridColumn={gridColumn} isSelected={isSelected}>
      <MapRailDrawerCell
        data-testid="legend-drawer"
        label={label}
        onClose={onClose}
      >
        <DrawerContent>
          <StyledLegend>{Component && <Component id={id} />}</StyledLegend>
        </DrawerContent>
      </MapRailDrawerCell>
    </MapRailDrawerListItem>
  );
};

const MapRailDrawerList = () => {
  const {
    state: { activeLegend },
    setActiveLegend,
  } = useActiveLayersContext();
  const { items, selectedItems } = useMapRailContext();

  return (
    <StyledMapRailDrawerList>
      {[...items].map(([id, item]) => {
        const { component: Component, props } = item;

        const isSelected = selectedItems.has(item.id);

        const selectedIndex = [...selectedItems].findIndex(
          ([selectedId]) => selectedId === item.id,
        );

        const lastPinnedIndex = [...selectedItems].findLastIndex(
          ([, selectedItem]) => selectedItem.isPinned,
        );
        return (
          <MapRailDrawerListItem
            gridColumn={getGridColumn({ lastPinnedIndex, selectedIndex })}
            isSelected={isSelected}
            key={id}
          >
            <Component {...props} />
          </MapRailDrawerListItem>
        );
      })}
      <MapRailLegendDrawer
        isSelected={!!activeLegend}
        onClose={() => setActiveLegend({ legend: null })}
        gridColumn={selectedItems.size ? 2 : 1}
        legend={activeLegend}
      />
    </StyledMapRailDrawerList>
  );
};

export default MapRailDrawerList;
