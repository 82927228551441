import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import { useMapServerQueryData } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const GOVERNMENT_RADIO_NETWORK_ID = "governmentRadioNetwork";

interface GovernmentRadioNetworkLayerProps {
  opacity?: number;
}

const GovernmentRadioNetworkLayer = ({
  opacity = 1,
}: GovernmentRadioNetworkLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/39/query`,
  );

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(GOVERNMENT_RADIO_NETWORK_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    loadImage({
      imageId: GOVERNMENT_RADIO_NETWORK_ID,
      map,
      src: "/icons/government-radio-network.png",
      signal: controller.signal,
    }).then(() => {
      map.addLayer(
        {
          id: GOVERNMENT_RADIO_NETWORK_ID,
          type: "symbol",
          source: GOVERNMENT_RADIO_NETWORK_ID,
          layout: {
            "icon-image": GOVERNMENT_RADIO_NETWORK_ID,
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      map.removeLayer(GOVERNMENT_RADIO_NETWORK_ID);
      map.removeSource(GOVERNMENT_RADIO_NETWORK_ID);
      map.removeImage(GOVERNMENT_RADIO_NETWORK_ID);
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(GOVERNMENT_RADIO_NETWORK_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(GOVERNMENT_RADIO_NETWORK_ID, "icon-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default GovernmentRadioNetworkLayer;
