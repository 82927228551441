import styled from "styled-components";
import type { AVLInteractionState } from "../../map/AVLPositions/interactions";
import type { MapPopupType } from "../SpatialPopup/MapPopup";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import AVLPopupDetail from "./AVLPopupDetail";
import AVLPopupHeader from "./AVLPopupHeader";
import AVLPopupSummary from "./AVLPopupSummary";

const StyledErrorHeader = styled.div`
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  margin: auto 0;
`;

export interface AVLPopupProps {
  avlType: "appliance" | "portable";
  "data-testid"?: string;
  isCopView: boolean;
  onClose: () => void;
  state: AVLInteractionState;
  type: MapPopupType;
}

const AVLPopup = ({
  avlType,
  "data-testid": dataTestId,
  isCopView,
  onClose,
  state,
  type,
}: AVLPopupProps) => {
  const hasClose = type === "click";

  return (
    <SpatialPopup
      isOpen={state.isActive}
      lngLat={state.properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id: state.id,
        offset: 16,
        size: "lg",
        type,
      }}
    >
      {state.properties?.data ? (
        <MapPopupView
          data-testid={dataTestId}
          hasClose={hasClose}
          header={
            <AVLPopupHeader
              status={state.properties.data.Status}
              type={avlType}
            />
          }
          onClose={onClose}
        >
          {type === "hover" ? (
            <AVLPopupSummary properties={state.properties} />
          ) : (
            <AVLPopupDetail
              isCopView={isCopView}
              properties={state.properties}
              type={avlType}
            />
          )}
        </MapPopupView>
      ) : (
        <MapPopupView
          data-testid={`${dataTestId}-error`}
          header={
            <StyledErrorHeader>
              Failed to load AVL information
            </StyledErrorHeader>
          }
          onClose={onClose}
        />
      )}
    </SpatialPopup>
  );
};

export default AVLPopup;
