import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import { isMaplibre } from "../utils/isMaplibre";

interface UseAVLPositionsMapLayersParams {
  layerId: string;
  opacity?: number;
  sourceId: string;
}

const useAVLPositionsMapLayers = ({
  layerId,
  opacity = 1,
  sourceId,
}: UseAVLPositionsMapLayersParams) => {
  const map = useMapContext();
  const theme = useTheme();

  useEffect(() => {
    const textLayerId = `${layerId}-text`;

    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: layerId,
        type: "symbol",
        source: sourceId,
        layout: {
          "icon-image": [
            "coalesce",
            ["image", ["get", "StatusCategory"]],
            ["image", "fallback"],
          ],
          "icon-allow-overlap": true,
          "icon-rotate": ["get", "DirectionHor"],
          "icon-rotation-alignment": "map",
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    map.addLayer(
      {
        id: textLayerId,
        type: "symbol",
        source: sourceId,
        minzoom: 8,
        layout: {
          "text-field": ["get", "CallSign"],
          "text-font": isMaplibre(map) ? ["Noto Sans Bold"] : ["DIN Pro Bold"],
          "text-offset": [1.5, 0],
          "text-max-width": 100,
          "text-anchor": "left",
          "text-size": 12,
        },
        paint: {
          "text-color": theme.colors.neutrals.text,
          "text-halo-color": "#FFF",
          "text-halo-width": 1,
          "text-halo-blur": 0,
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getLayer(textLayerId)) {
        map.removeLayer(textLayerId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, [layerId, map, sourceId, theme.colors.neutrals.text]);

  useEffect(() => {
    map.setPaintProperty(layerId, "icon-opacity", opacity);
  }, [layerId, map, opacity]);
};

export default useAVLPositionsMapLayers;
